import { Formik } from "formik";
import { edit } from "network/ApiAxios";
import { register } from "network/ApiAxios";
import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
} from "reactstrap";
import * as Yup from "yup";

const AddEditUser = ({ isOpen, toggleModal, data, setsuccess }) => {
    const id = data?._id ? true : false;
    const handleSubmit = async (values) => {
        console.log(values);
        if (!values._id) {
            const response = await register(
                values.name,
                values.email,
                values.password,
                values.contact,
                values.designation,
            );
            if (response?.data?.success) {
                setsuccess(true);
                toggleModal();
            }
        } else {
            // Handle else case if needed
            const response = await edit(
                values._id,
                values.name,
                values.email,
                values.contact,
                values.designation,
            );
            if (response?.data?.success) {
                setsuccess(true);
                toggleModal();
            }
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        password: Yup.string().when("$id", {
            is: false, // if data._id is not available
            then: (schema) => schema.required("Password is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        contact: Yup.string().required("Contact is required"),
        designation: Yup.string().required("Designation is required"),
    });

    const initialValues = {
        name: data?.name || "",
        email: data?.email || "",
        password: "",
        contact: data?.contact || "",
        designation: data?.designation || "",
        _id: data?._id || "", // Include _id in initial values to use it in validation
    };

    return (
        <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} toggle={toggleModal}>
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-0">
                        <div className="text-muted text-left mt-2 mb-3">
                            {data?._id ? "Update Admin Info" : "Add New Admin"}
                        </div>
                    </CardHeader>
                    <CardBody className=" px-lg-5 py-lg-4">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => handleSubmit(values)}
                            enableReinitialize
                        >
                            {({ handleSubmit, handleChange, values, touched, errors }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    placeholder="Name"
                                                    type="text"
                                                    name="name"
                                                    value={values?.name}
                                                    onChange={handleChange}
                                                />
                                                {touched.name && errors.name && (
                                                    <div className="text-danger">{errors.name}</div>
                                                )}
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    name="email"
                                                    value={values?.email}
                                                    onChange={handleChange}
                                                />
                                                {touched.email && errors.email && (
                                                    <div className="text-danger">
                                                        {errors.email}
                                                    </div>
                                                )}
                                            </InputGroup>
                                        </FormGroup>
                                        {!data?._id && (
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative">
                                                    <Input
                                                        placeholder="Password"
                                                        type="password"
                                                        name="password"
                                                        onChange={handleChange}
                                                    />
                                                    {touched.password && errors.password && (
                                                        <div className="text-danger">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        )}
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    name="contact"
                                                    value={values?.contact}
                                                    onChange={handleChange}
                                                />
                                                {touched.contact && errors.contact && (
                                                    <div className="text-danger">
                                                        {errors.contact}
                                                    </div>
                                                )}
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    placeholder="Designation"
                                                    type="text"
                                                    name="designation"
                                                    value={values?.designation}
                                                    onChange={handleChange}
                                                />
                                                {touched.designation && errors.designation && (
                                                    <div className="text-danger">
                                                        {errors.designation}
                                                    </div>
                                                )}
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button className="my-4" color="primary" type="Submit">
                                                {data?._id ? "Update" : "Create"}
                                            </Button>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default AddEditUser;
